import React, {forwardRef, useId} from "react";

interface InputProps {
    onChange?: React.FormEventHandler<HTMLInputElement>,
    onBlur?: React.FormEventHandler<HTMLInputElement>,
    defaultValue?: string,
    className?: string,
    required?: boolean,
    value?: string,
    name?: string,
    label?: string,
    type?: 'text' | 'email' | 'password' | 'tel' | 'date'
    onClick?: any//React.MouseEventHandler<HTMLInputElement> | React.MouseEventHandler<HTMLLabelElement>
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
                                                            className,
                                                            defaultValue,
                                                            required,
                                                            onBlur,
                                                            onChange,
                                                            name,
                                                            type,
                                                            label,
                                                            onClick,
                                                            value
                                                        }, ref) => {

    const id = useId();

    return (
        <div className={`relative ${className}`}>
            <input
                autoComplete={'false'}
                role={'presentation'}
                id={`${id}-input`}
                name={`${name}`} defaultValue={defaultValue} ref={ref} onChange={onChange} required={required}
                onBlur={onBlur} type={type}
                className={`rounded-none  text-14 border border-gray-light h-[39px] block px-2.5 py-[6px]  w-full text-17 text-gray-dark  appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer ${className}`}
                placeholder=" "/>
            <label htmlFor={`${id}-input`}
                   className="absolute  text-gray-dark  uppercase duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 left-1">
                {label}
            </label>
        </div>
    )
});

export default Input