import React from 'react';

interface PanelContentProps {
    children?: React.ReactNode,
    className? : string
}

const Panel = ({children,className}: PanelContentProps) => {
    return (
        <div
            className={`flex-grow flex flex-col w-full px-20 py-20 overflow-auto h-full ${className}`}>
            {children}
        </div>
    );
}

export default Panel;