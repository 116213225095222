import React, {forwardRef, useId} from "react";

interface InputProps {
    children?: React.ReactNode,
    onChange?: React.FormEventHandler<HTMLInputElement>,
    onBlur?: React.FormEventHandler<HTMLInputElement>,
    defaultValue?: string,
    className?: string,
    required?: boolean,
    value?: string,
    name?: string,
    checked?: boolean,
    label?: string,
    onClick?: React.MouseEventHandler<HTMLInputElement>
}

const CheckBox = forwardRef<HTMLInputElement, InputProps>(({
                                                               className,
                                                               children,
                                                               defaultValue,
                                                               required,
                                                               onBlur,
                                                               checked,
                                                               onChange,
                                                               name,
                                                               label,
                                                               onClick,
                                                               value
                                                           }, ref) => {

    const id = useId();

    return (
        <div className={`flex items-center  ${className}`}>
            <input
                name={name}
                defaultValue={defaultValue}
                ref={ref}
                checked={checked}
                onChange={onChange}
                required={required}
                onBlur={onBlur}
                onClick={onClick}
                id={`${id}-input`}
                value={value}
                type="checkbox"
                className="opacity-0 absolute w-[30px] h-[30px] cursor-pointer"/>
            <div
                className="cursor-pointer bg-white border border-gray-light w-[14px] h-[14px] flex flex-shrink-0 justify-center  items-center mr-2 focus-within:border-gray-light mr-[10px]">
                <svg className="pointer-events-none fill-current hidden w-3 h-3 text-primary pointer-events-none" version="1.1"
                     viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(-9 -11)" fill="currentColor" fillRule="nonzero">
                            <path
                                d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"/>
                        </g>
                    </g>
                </svg>
            </div>
            <label htmlFor={`${id}-input`} className="text-10 text-gray-dark font-serif  select-none"
                   dangerouslySetInnerHTML={{__html: label || ''}} />
        </div>
    )
});

export default CheckBox

