import gsap from "gsap";
import {Graphics, Sprite, Text, Texture} from "pixi.js";

export default class TilePicture extends Sprite {
    private _g: Graphics;
    constructor() {
        super();
        this.alpha = 0;
        this.position.set(-9999,-99999);

        this._g = new Graphics();
        //this.addChild(this._g);

    }

    async update(data: any, path : string) {

        this.position.set(data.rect.x, data.rect.y);
        this.width = data.rect.width;
        this.height = data.rect.height;

        this._g.clear();
        this._g.lineStyle(1,0xFF0000);
        this._g.beginFill(0xFF0000,0);
        this._g.drawRect(0,0,data.rect.width,data.rect.height)

        try {

            if(this.texture.baseTexture.cacheId !==  `${path}/${data.data}`) {
                this.texture = Texture.EMPTY;
                this.texture = await Texture.fromURL( `${path}/${data.data}`);
                gsap.to(this, {alpha : 1});
                this.emit<any>('ready');

            }

        } catch (e) {
            //console.log('e',e)
        }
    }

    isLoaded () {
        return this.texture.valid;
    }

    reset() {
        //this.texture.baseTexture.destroy()
        this.texture = Texture.EMPTY;
        /*
        this._graphic.clear();
        this._text.text = '';
       // this.texture.destroy(false)
        */
        this.alpha = 0;
        this.position.set(-9999,-99999)
    }
}