import React, {useEffect} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {ConnectForm, SimulationFormData} from "./Simulation";
import Input from "./Input";
import {useTranslation} from "react-i18next";
import CheckBox from "./CheckBox";


export interface InfosProps {
    back: Function
}

const Infos = ({back}: InfosProps) => {

    const {panorama, spotID, logoID} = useParams();
    const {t, i18n} = useTranslation();

    return (
        <ConnectForm<SimulationFormData> >

            {({register,getValues, control, formState: {errors}}) => (
                <div>
                    <h2 className={'text-primary mb-4 flex'}>
                        <span>{t('simulation.step3.title')}</span>
                    </h2>
                    <Input className={'mb-4'}
                           label={t('simulation.step3.placeholder')}
                           {...register("email", {required: true})}
                    />
                    {errors.email && <p className={'-mt-4 text-red-500 text-10 mb-2'}>
                        {t('simulation.step3.form.errors.email')}
                    </p>}
                    <CheckBox className={'mb-4'}
                              label={t('simulation.step3.legals')}
                              {...register("agreeLegals", {required: true})} />
                    {errors.agreeLegals && <p className={'-mt-4 text-red-500 text-10 mb-2'}>
                        {t('simulation.step3.form.errors.legals')}
                    </p>}
                    <CheckBox className={'mb-4'}
                              label={t('simulation.step3.accept')}
                              {...register("agreeContact", {required: false})} />
                    <button className={'bg-primary text-white w-full p-5 mt-2 rounded-lg text-11'}>
                        {t('simulation.step3.cta')}
                    </button>
                </div>
            )}
        </ConnectForm>
    );
}

export default Infos;