import {Container, Graphics, IDestroyOptions, Sprite} from "pixi.js";
import * as PIXI from "pixi.js";
import {Viewport} from "pixi-viewport";
import {gsap} from 'gsap';
import {PixiPlugin} from "gsap/PixiPlugin";

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Building extends Container {
  
  private _spot: string | undefined;
  private _graphics: Graphics;
  private _graphicsOut: Graphics;
  private _sprite: Sprite;
  private _onMouseEnterBinded: OmitThisParameter<() => void>;
  private _onMouseLeaveBinded: OmitThisParameter<() => void>;
  private _active: Boolean = false;
  
  constructor() {
    super();
    
    this._sprite = new Sprite();
    this.addChild(this._sprite);
    
    this._graphics = new Graphics();
    this._graphics.interactive = false;
    this.addChild(this._graphics);
    
    this._graphicsOut = new Graphics();
    this._graphicsOut.interactive = false;
    this.addChild(this._graphicsOut);
    
    this._onMouseEnterBinded = this.onMouseEnter.bind(this);
    this._onMouseLeaveBinded = this.onMouseLeave.bind(this);
    
    this.on('pointerenter', this._onMouseEnterBinded);
    this.on('pointerleave', this._onMouseLeaveBinded);
    
    this.interactive = true;
    this.cursor = 'pointer';
    
  }
  
  destroy(_options?: IDestroyOptions | boolean) {
    super.destroy(_options);
    this.off('pointerenter', this._onMouseEnterBinded);
    this.off('pointerleave', this._onMouseLeaveBinded);
  }
  
  onMouseEnter() {
    if (!this._active) {
      this.killTween()
      gsap.to(this._graphicsOut, {duration: .35, pixi: {tint: 0xFFFFFF}});
      gsap.to(this._graphics, {duration: .35, pixi: {scale: 1.5, tint: 0xFFFFFF}});
    }
  }
  
  onMouseLeave() {
    if (!this._active) {
      this.killTween()
      gsap.to(this._graphicsOut, {duration: .35, pixi: {tint: 0xFFFFFF}});
      gsap.to(this._graphics, {duration: .35, pixi: {scale: 1, tint: 0xFFFFFF}});
    }
  }
  
  async update(data: any, viewport: Viewport) {
    this._spot = data.id;
    this.position.set(data.rect.x * viewport.width, data.rect.y * viewport.height);
    this._graphicsOut.clear();
    this._graphicsOut.beginFill(0xFFFFFF, .4);
    this._graphicsOut.drawCircle(0, 0, 90);
    
    this._graphics.clear();
    this._graphics.beginFill(0xFFFFFF, .8);
    this._graphics.drawCircle(0, 0, 30);
  }
  
  killTween() {
    gsap.killTweensOf(this._graphicsOut);
    gsap.killTweensOf(this._graphics);
  }
  
  loop() {
    if (this._active) {
      gsap.to(this._graphicsOut, {
        duration: 1,
        startAt: {pixi: {tint: 0xFFFFFF}},
        pixi: {scale: .7, tint: 0xFFFFFF},
        repeat: -1,
        yoyo: true
      });
      gsap.to(this._graphics, {duration: 1, pixi: {scale: 1, tint: 0xFFFFFF}});
    }
  }
  
  reset() {
    gsap.to(this._graphicsOut, {duration: .35, pixi: {width: 180, height: 180, tint: 0xFFFFFF}});
    gsap.to(this._graphics, {duration: .35, pixi: {width: 60, height: 60, tint: 0xFFFFFF}});
  }
  
  setActive(bool: Boolean) {
    this._active = bool
    this.killTween()
    if (this._active) {
      this.loop()
    } else {
      this.reset()
    }
  }
  
  get spot() {
    return this._spot;
  }
  
}