import {
    Application,
    Container,
    Filter,
    filters,
    Graphics,
    Rectangle,
    RenderTexture,
    Sprite,
    Text,
    Texture
} from "pixi.js";
import {Viewport} from "pixi-viewport";
import {AdvancedBloomFilter} from '@pixi/filter-advanced-bloom';

export default class Placeholder extends Container {

    private _id: any;
    private _graphics: Graphics;
    private _sprite: Sprite;
    private _data: any;
    private _h: number;
    private _w: number;
    private _application: Application;
    private _container: Container;

    constructor(application : Application) {
        super();

        this._container = new Container();
        this.addChild(this._container )


        this._sprite = new Sprite();
        this._container.addChild(this._sprite);

        this._graphics = new Graphics();
        this._container.addChild(this._graphics);

        this._w = 1;
        this._h = 1;

        this._application = application;
    }

    async setLogo(url: string) {
        this.cacheAsBitmap = false;
        // this._sprite.texture = Texture.EMPTY;

        this._sprite.texture = await Texture.fromURL(url);
        this._sprite.position.set(this._w / 2, this._h / 2);

        this._sprite.scale.set(Math.min(this._w / this._sprite.texture.width, this._h / this._sprite.texture.height));
        this._sprite.anchor.set(0.5);

        const frag = `
        varying vec2 vTextureCoord;
        uniform sampler2D uSampler;

        void main(void){
           gl_FragColor = texture2D(uSampler, vTextureCoord);
           gl_FragColor.rgb = vec3(1.,1.,1.);
           gl_FragColor *= gl_FragColor.a;
        }
        `;

        const advancedBloom = new AdvancedBloomFilter({
            brightness: 1,
            resolution: 3,
            quality: 10,
            blur: 1,
            bloomScale: .3
        });

        advancedBloom.padding = 10;

        const advancedBloomLayer2 = new AdvancedBloomFilter({
            brightness: 1,
            resolution: 3,
            quality: 10,
            blur: 6,
            bloomScale: .5
        });

        const color = new filters.ColorMatrixFilter();
        color.tint(0xeaf8ff,true );

        advancedBloomLayer2.padding = 10;

        const g = new Graphics();
        g.beginFill(0xFF0000, 0.001);
        g.drawRect(-500, -500, 1000, 1000);

        this._sprite.addChild(g);

        this._sprite.filters = [
            color,
            new Filter(undefined, frag),
            advancedBloomLayer2,
            advancedBloom,
        ]

        this.cacheAsBitmap = true;

    }

    async update(data: any, viewport: Viewport) {

        this._id = data.id;

        this._w = data.rect.width * viewport.width;
        this._h = data.rect.height * viewport.height;
        this.rotation = data.rotation;
        this.position.set(data.rect.x * viewport.width, data.rect.y * viewport.height);
        this._sprite.skew.y = data.skew.y;
        this._sprite.skew.x = data.skew.x;
        this._sprite.rotation = data.rotation;


        /*this._graphics.clear();
        this._graphics.beginFill(0xFF00F0, 0);
        this._graphics.rotation = data.rotation;
        this._graphics.skew.y = data.skew.y;
        this._graphics.skew.x = data.skew.x;
        this._graphics.drawRect(0, 0, this._w, this._h);*/

        this.interactive = false;
        this.interactiveChildren = false;
    }

    get id() {
        return this._id;
    }

    reset() {
        // this._sprite.texture = Texture.EMPTY;
    }
}