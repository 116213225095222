import React, {} from 'react';
import PanelContent from "./PanelContent";
import {useTranslation} from "react-i18next";
import {NavLink, useParams, useRouteLoaderData} from "react-router-dom";
import {ROUTES} from "../constants/routes";

const Panoramas = () => {

    const {t, i18n} = useTranslation();

    const {panorama} = useParams();

    return (
        <PanelContent>
            <h1 className={'text-primary font-extrabold font-sans line'}>
                {t('panorama.title')}
            </h1>
            <h3 className={'text-primary mb-6'}>{t('panorama.description')}</h3>
           <div className={'flex flex-col gap-4'}>
               {(t('panorama.list', {returnObjects: true}) as []).map((item: any, index: number) => {
                  return  <NavLink className={`PanoBtn overflow-hidden rounded-lg cursor-pointer ${panorama === item.slug ? 'active' : ''}`} key={item.slug} to={`/${item.slug}/${ROUTES.PANO}`}>
                      <img className={'w-full h-[60px] object-cover object-center'} src={`/tiles/${item.slug}/thumbnail.webp`} />
                  </NavLink>
               })}
           </div>
        </PanelContent>
    );
}

/*
  <NavLink to={`/pont-de-bergues/${ROUTES.PANO}`}>Pont de Bergues</NavLink>
  <NavLink to={`/quai-gustave-ador/${ROUTES.PANO}`}>Quai Gustave-Ador</NavLink>
 */

export default Panoramas;