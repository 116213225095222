import {useContext, createContext, useReducer, useMemo} from "react";

export const RendererContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'setActive' :
      return {
        ...state,
        activeViewport: action.payload
      };
  }
}

export const RendererContextProvider = props => {

  const initialState = {
    activeViewport: true
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return <RendererContext.Provider value={{...contextValue}} {...props} />
}

export const useRendererState = () => {
  const app = useContext(RendererContext)
  return {...app}
}

