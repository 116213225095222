import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Renderer from "../components/Renderer";
import {NavLink, Outlet, useLoaderData, useLocation, useParams} from "react-router-dom";
import {ROUTES} from "../constants/routes";

import {ReactComponent as Place} from "../assets/svg/icon-place-holder.svg";
function Home() {
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const data = useLoaderData();
  const {panorama} = useParams();
  
  const [uniqueData, setUniqueData] = useState<any>(null);

  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    (t('panorama.list', {returnObjects: true}) as []).forEach((item : any) => {
      if(item.slug === panorama) {
        setLabel(item.label);
      }
    })
  }, [panorama]);
  
  useEffect(() => {
    if(JSON.stringify(data) !== JSON.stringify(uniqueData)) {
      setUniqueData(data);
    }
  }, [data])
  

  return (
    <motion.div
      className="w-full h-full"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{}}
      transition={{duration: .15}}
    >
      <div className={'absolute left-16 top-16  z-20 text-white z-20 flex items-center'}>
        <Place className={'text-white w-6 h-6 mr-4'} />
        <span className={'text-14'} dangerouslySetInnerHTML={{__html: label}} />
      </div>

      <div className={'relative h-full flex flex-col justify-between gap-10 text-white z-10'}>

        <nav className={'fixed flex bottom-16 left-16 gap-16'}>
          <NavLink className={'button button--red'}
                   to={`/${panorama}/${ROUTES.SIMU}`}
          >
            {t('home.simulation')}
          </NavLink>
          <NavLink className={'button button--white'}
                   to={`/${panorama}/${ROUTES.PANO}`}
          >
            {t('home.panorama')}
          </NavLink>
        </nav>
      </div>
      <Outlet/>
      <Renderer data={uniqueData}/>
    </motion.div>
  );
}

export default Home;
