import React, {} from 'react';
import {useTranslation} from "react-i18next";
import PanelContent from "./PanelContent";
import {ReactComponent as Hand} from '../assets/svg/icon-pan.svg';
import {ReactComponent as Select} from '../assets/svg/icon-clic.svg';
const Intro = () => {

    const {t, i18n} = useTranslation();

    return (
        <PanelContent>
            <h1 className={'text-primary font-extrabold font-sans line'}>
                {t('intro.title')}
            </h1>
            <p>{t('intro.description')}</p>
            <div className={'mt-16'}>
                <h3 className={'line'}>{t('intro.move')}</h3>
                <span className={'flex items-center'}>
                    <Hand className={'block w-20 h-20 flex-shrink-0 mr-10'} />
                    <p>{t('intro.moveDesc')}</p>
                </span>
            </div>
            <div className={'mt-16'}>
                <h3 className={'line'}>{t('intro.select')}</h3>
                <span className={'flex items-center'}>
                    <Select className={'block w-20 h-20 flex-shrink-0 mr-10'} />
                    <p>{t('intro.selectDesc')}</p>
                </span>
            </div>
        </PanelContent>
    );
}

export default Intro;