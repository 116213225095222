import {IDisposable} from "./idisposable";

export default abstract class Resizable implements IDisposable {
    private  _resizeBinded: () => void;
    private _resizeDebouncedBinded: () => void;
    private _idTimeout: number;

    constructor() {
        this._idTimeout= -1;
        this._resizeBinded = this.resize.bind(this);
        this._resizeDebouncedBinded = this._resizeDebounced.bind(this);
        window.addEventListener('resize', this._resizeDebouncedBinded);
    }
    
    _resizeDebounced() {
        clearTimeout(this._idTimeout)
        this._idTimeout = window.setTimeout(this._resizeBinded,100);
    }

    abstract resize(): void;

    destroy(): void {
        window.removeEventListener('resize', this._resizeBinded);
    }
}