import React, {useEffect, useRef, useState} from "react";
import {
  Application,
  Container,
  Sprite,
  Renderer as WebglRenderer,
  RenderTexture,
} from "pixi.js";
import Tilemap from "../nother/game/tilemap";
import {Viewport} from "pixi-viewport";
import TilePicture from "../renderer/TilePicture";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Placeholder from "../renderer/Placeholder";
import Building from "../renderer/Building";
import {ROUTES} from "../constants/routes";
import {useRendererState} from "../RendererContext";

interface RendererProps {
  data: any,
  position?: any,
  captureReady?: Function
}

const SIZE = 1024;

const Renderer = ({data, position, captureReady}: RendererProps) => {
  
  const location = useLocation();
  const history = useNavigate();
  const {state, dispatch} = useRendererState();
  
  const canvas = useRef<HTMLCanvasElement>(null);
  
  const {panorama, spotID, logoID} = useParams();
  const [tilemap, setTilemap] = useState<Tilemap | null>(null);
  const [viewport, setViewPort] = useState<Viewport | null>(null);
  const [application, setApplication] = useState<Application | null>(null)
  const [ready, setReady] = useState(false);
  const [readyForCapture, setReadyForCapture] = useState(false);
  
  
  const onSpotClick = (e: any, spot: any) => {
    if (location.pathname.indexOf(ROUTES.SIMU) > -1) {
      history(`/${panorama}/${ROUTES.SIMU}/${e.target.spot}`);
    } else {
      history(`/${panorama}/${ROUTES.LOGOS}/${e.target.spot}`);
    }
    
    if (spot instanceof Building) {
      viewport?.children?.forEach(child => {
        if (child instanceof Building) {
          if (child === spot) {
            child.setActive(true)
          } else {
            child.setActive(false)
          }
        }
      })
    }
  }
  
  useEffect(() => {
    if (viewport) {
      
      viewport.children?.forEach(child => {
        if (child instanceof Building) {
          child.on('click', (e) => onSpotClick(e, child));
        }
      });
      
      return () => {
        viewport.children?.forEach(child => {
          child.off('click', (e) => onSpotClick(e, child));
        });
      }
    }
  }, [viewport, location])
  
  useEffect(() => {
    
    if (data && application && tilemap && viewport && viewport.transform) {
      
      tilemap.updateData(data);
      
      data.placeholders.forEach((placeholder: any) => {
        const phSprite = new Placeholder(application);
        phSprite.update(placeholder, data.viewport);
        viewport.addChild(phSprite);
      });
      
      if (!position) {
        data.buildings.forEach((placeholder: any) => {
          const buildSprite = new Building();
          buildSprite.on('click', (e) => onSpotClick(e, buildSprite));
          buildSprite.update(placeholder, data.viewport);
          viewport.addChild(buildSprite);
        });
      }
      
    }
    
    return () => {
      if (viewport) {
        viewport.children?.forEach(child => {
          child.off('click', (e) => onSpotClick(e, child));
          child.destroy(true);
        });
        viewport.removeChildren();
      }
      
    }
    
  }, [data, tilemap, application, viewport, position]);
  
  useEffect(() => {
    
    const load = async () => {
      
      let placeholder: Placeholder | undefined;
      viewport?.children?.forEach((children: any) => {
        if (children.id === spotID) {
          placeholder = children as Placeholder;
        }
      });
      
      if (placeholder) {
        await placeholder.setLogo(`/logos/${logoID}_crop.png`);
      }
    }
    
    if (logoID && spotID && viewport) {
      load();
    }
    
  }, [location, logoID, spotID, viewport]);
  
  useEffect(() => {
    
    if (position && application && viewport && viewport.transform && data) {
      
      const init = async () => {
        
        console.log('INIT LOGO');
        
        let placeholder: Placeholder | undefined;
        
        viewport.children?.forEach((children: any) => {
          if (children.id === position.id) {
            placeholder = children as Placeholder;
          }
        });
        
        if (placeholder) {
          await placeholder.setLogo(position.logo);
        }
        
        viewport.resize(SIZE, SIZE, data.viewport.width, data.viewport.height);
        
        viewport.ensureVisible(
          (position.x * data.viewport.width) - SIZE / 2 + (position.width * data.viewport.width) / 2,
          (position.y * data.viewport.height) - SIZE / 3,
          SIZE,
          SIZE,
          true
        )
        
        viewport.setZoom(.85, true);
        
        setReadyForCapture(true);
      }
      
      init();
      
    }
  }, [position, application, viewport, data]);
  
  useEffect(() => {
    if (ready && readyForCapture) {
      setReadyForCapture(false);
      setTimeout(() => {
        takeScreenshot();
      }, 1000);
    }
    
  }, [ready, readyForCapture]);
  
  
  useEffect(() => {
    
    if (tilemap && application && viewport && application.renderer) {
      
      const onResize = () => {
        
        const w = position ? SIZE : window.innerWidth;
        const h = position ? SIZE : window.innerHeight;
        
        application.renderer.resize(w, h);
        
        tilemap.resize();
        
        if (data) {
          viewport.resize(w, h, data.viewport.width, data.viewport.height);
          viewport.clampZoom({maxScale: 1.7, minScale: h / data.viewport.height})
          if (!position) {
            viewport.fitWorld(true);
          }
        }
        console.log('resize')
      }
      
      window.addEventListener('resize', onResize);
      
      onResize();
      
      return () => {
        window.removeEventListener('resize', onResize);
      }
    }
    
  }, [tilemap, viewport, application, data, position]);
  
  useEffect(() => {
    
    if (tilemap && application && application.stage && viewport) {
      
      const tileContainer = new Container();
      application.stage.addChildAt(tileContainer, 0);
      
      const bgTexture = Sprite.from(`/tiles/${panorama}/blur.webp`);
      
      bgTexture.width = data.viewport.width;
      bgTexture.height = data.viewport.height;
      application.stage.addChildAt(bgTexture, 0);
      
      const pool: any[] = [];
      
      for (let i = 0; i < 30; i++) {
        pool.push(new TilePicture());
      }
      
      const displayList: any = {};
      
      const onTileReady = () => {
        let allLoaded = false;
        
        Object.keys(displayList).forEach((key: string) => {
          if (displayList[key]) {
            allLoaded = displayList[key].isLoaded();
          }
        });
        
        
        if (allLoaded) {
          setReady(true);
          
        } else {
          setReady(false);
          
        }
      }
      
      const updateDisplay = (l: any, time: number) => {
        
        let allLoaded = true;
        
        Object.keys(l).forEach(key => {
          
          if (!displayList[key]) {
            const poolItem: any = pool.pop();
            if (poolItem) {
              displayList[key] = poolItem;
              tileContainer.addChild(displayList[key]);
            }
          }
          
          if (displayList[key]) {
            displayList[key].on('ready', onTileReady);
            displayList[key].update(l[key], `/tiles/${panorama}`);
            displayList[key].time = time;
          }
          
        });
        
        //CLEANING
        Object.keys(displayList).forEach((key: string) => {
          if (displayList[key].time !== time) {
            displayList[key].reset();
            displayList[key].off('ready', onTileReady);
            pool.push(displayList[key]);
            tileContainer.removeChild(displayList[key]);
            delete displayList[key];
          }
        });
        
        return allLoaded;
      }
      
      
      const update = (time: number) => {
        
        if (viewport.dirty) {
          //console.log('UPDATE')
          viewport.dirty = false;
          
          const list = tilemap.draw({
            zoom: viewport.scaled,
            x: -viewport.x,
            y: -viewport.y,
            width: viewport.screenWidth,
            height: viewport.screenHeight
          });
          
          const scale = (bgTexture.texture.baseTexture.width / viewport.worldWidth);
          bgTexture.scale.x = viewport.scaled / scale;
          bgTexture.scale.y = viewport.scaled / scale;
          bgTexture.position.set(viewport.x, viewport.y);
          
          updateDisplay(list, time);
        }
      }
      
      application.ticker.add(update);
      
      return () => {
        pool.length = 0;
        application.ticker.remove(update);
        application.stage.removeChild(tileContainer);
        tileContainer.destroy();
        application.stage.removeChild(bgTexture);
        bgTexture.destroy();
        tileContainer.destroy();
      }
    }
    
  }, [tilemap, viewport, application, data])
  
  useEffect(() => {
    if (canvas.current) {
      const app = new Application({
        resolution: position ? 1 : Math.max(window.devicePixelRatio * .75, 1),
        antialias: true,
        view: canvas.current,
      });
      
      setApplication(app);
      
      const tm: Tilemap = new Tilemap(app.stage);
      setTilemap(tm);
      
      const viewport = new Viewport({
        interaction: app.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
      });
      
      viewport
        .drag()
        .pinch()
        .wheel()
        .decelerate()
        .clamp({direction: 'all'})
      
      app.stage.addChild(viewport);
      setViewPort(viewport);
      
      return () => {
        
        if (app) {
          app.destroy(false, true);
          tm.destroy();
          
          viewport.children?.forEach(child => {
            
            child.off('click', (e) => onSpotClick(e, child));
            child.destroy(true);
          });
          
          viewport.removeChildren();
          
          viewport.destroy({children: true, texture: true, baseTexture: true});
        }
        
        setApplication(null);
        setViewPort(null);
        setTilemap(null);
      }
    }
  }, []);
  
  useEffect(() => {
    if (viewport) {
      viewport.pause = !state.activeViewport;
    }
    
  }, [viewport, state.activeViewport])
  
  useEffect(() => {
    if (spotID && logoID && data && viewport) {
      //const spot = data.placeholders.find((item: any) => item.id === spotID)
      
    
      /*const y = (spot.rect.y * data.viewport.height);
      const x =  (spot.rect.x * data.viewport.width)
      viewport.setZoom(.5);
      viewport.moveCenter({x,y})*/
      
    }
  }, [spotID, logoID, data, viewport])
  
  const takeScreenshot = async () => {
    if (application) {
      application.stop();
      
      //console.log('capture')
      
      await (application.renderer as WebglRenderer).prepare.upload(application.stage);
      
      setTimeout(async () => {
        const renderTexture = RenderTexture.create({width: 1024, height: 1024});
        application.renderer.render(application.stage, {renderTexture});
        const url = await (application.renderer as WebglRenderer).extract.base64(renderTexture, 'image/jpeg');
        if (captureReady) {
          captureReady(url);
        }
        /*const a = document.createElement('a');
        document.body.append(a);
        a.download = 'screenshot';
        a.href = url;
        a.click();
        a.remove();*/
      }, 1000)
      
    }
  }
  
  return (
    
    <canvas ref={canvas}
            className={`fixed z-0 left-0 top-0 ${position ? 'opacity-0 pointer-events-none left-[2000px] w-[1024px] h-[1024px]' : 'w-full h-full'}`}></canvas>
  
  );
}
//  {ready && <span className={'absolute z-50 left-0 top-0 bg-red-500 text-white text-16'}>READY</span>}

export default Renderer;
//opacity-0 pointer-events-none right-[2000px]