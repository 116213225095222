import {Outlet, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as Icon} from "../assets/svg/icon-replie.svg";
import {useRendererState} from "../RendererContext";

const Panel = () => {
  let location = useLocation();
  const [status, setStatus] = useState<'close' | 'open'>('close');
  const ref = useRef<HTMLDivElement | null>(null)
  
  const {state, dispatch} = useRendererState();
  
  const toggle = () => {
    setStatus(s => {
      return s === 'close' ? 'open' : 'close';
    });
  }
  
  useEffect(() => {
    setStatus('open');
  }, [location])
  
  
  useEffect(() => {
    if (ref.current) {
      
      const onEnter = () => {
        dispatch({type: 'setActive', payload: false})
      };
      const onLeave = () => {
        dispatch({type: 'setActive', payload: true})
      };
      
      ref.current.addEventListener('mouseenter', onEnter);
      ref.current.addEventListener('mouseleave', onLeave);
      
      return () => {
        if(ref.current) {
          ref.current.removeEventListener('mouseenter', onEnter);
          ref.current.removeEventListener('mouseleave', onLeave);
        }
       
      }
    }
  }, [])
  
  return (
    <div ref={ref}
         className={`fixed right-0 top-0 z-10 h-full bg-white w-1/3 transition-transform ${status === 'close' ? 'translate-x-[95%]' : 'translate-x-0'}`}>
      <button onClick={toggle} className={'absolute left-0 top-1/2 -translate-y-1/2  -translate-x-[30px]'}>
        <Icon className={`w-20 h-20 transition-transform ${status === 'close' ? '' : 'rotate-180'}`}/>
      </button>
      <Outlet/>
    </div>
  );
}

export default Panel;