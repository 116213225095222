import React, {} from 'react';
import PanelContent from "./PanelContent";
import {ReactComponent as Check} from '../assets/svg/icon-check.svg';
import {useTranslation} from "react-i18next";

const Sent = () => {

    const {t, i18n} = useTranslation();

    return (
        <PanelContent className={'px-0 py-4'}>
            <span className={'flex w-full'}>
                    <Check className={'block w-10 h-10 flex-shrink-0 mr-4'}/>
                    <p>{t('simulation.step4.description')}</p>
                </span>
        </PanelContent>
    );
}

export default Sent;