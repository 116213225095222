import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

interface IFileErrorProps {
    message : string,
    retry : Function
}

const FileError = ({message,retry}: IFileErrorProps) => {

    const {t} = useTranslation();

    const spring = {
        type: 'tween',
        ease : 'circOut'
    }

    return (
        <div
            className={`absolute inset-0`}>

            <motion.span
                transition={{...spring, duration: .5}}
                className={'absolute inset-0 bg-red-500/90 z-10 text-white flex flex-col gap-4 items-center justify-center px-10 '}
                initial={{x: '-100%'}}
                animate={{x: '0%'}}
                exit={{x: '-100%'}}
            >
                <span>{message}</span>

                <button type={'button'} className={'hover:bg-black hover:text-white text-black text-10 px-4 py-2 z-20 bg-white'} onClick={() => retry()}>{t('simulation.step2.cta')}</button>
            </motion.span>
        </div>
    )
}

export default FileError