import Resizable from "../resizable";
import {Container, Graphics} from "pixi.js";

interface ITile {

}

export default class Tilemap extends Resizable {

    private _tileWidth: number;
    private _tileHeight: number;
    private _data: any;

    constructor(stage: Container) {
        super();

       this._tileWidth = 1;
       this._tileHeight = 1;
        this._data = null;
    }

    updateData(data: any) {
        this._data = data;
    }

    getTile(layer: string[], col: number, row: number, size : number): string {
        return layer[row % size * size + col % size];
    }

    draw(camera: any) {

        const displayList: any = {};

        if (this._data) {
            let zoom = 'zoom-4';
            if(camera.zoom > 0 && camera.zoom < .26) {
                zoom = 'zoom3';
            }else if (camera.zoom >= .26 && camera.zoom < .7) {
                zoom = 'zoom2';
            }else {
                zoom = 'zoom1';
            }

            const layer = this._data[zoom];
            const grid = layer.grid;
            const cols = layer.cols;
            const rows = layer.rows;

            const scale = 1 / layer.scale;

            this._tileWidth = (layer.width / cols * scale) * camera.zoom;
            this._tileHeight = (layer.height / rows * scale) * camera.zoom;

            const startCol = Math.floor((camera.x) / this._tileWidth);

            const endCol = startCol + ((camera.width + this._tileWidth) / this._tileWidth);

            const startRow = Math.floor(camera.y / this._tileHeight);

            const endRow = startRow + ((camera.height + this._tileHeight) / this._tileHeight);

            const offsetX = -camera.x + startCol * this._tileWidth;

            const offsetY = -camera.y + startRow * this._tileHeight;

            for (let c = startCol; c <= endCol; c++) {
                for (let r = startRow; r <= endRow; r++) {

                    const tile = this.getTile(grid, c, r,cols);

                    if (tile && c < cols && c >= 0 && r < rows) {
                        const x = (c - startCol) * this._tileWidth + offsetX;
                        const y = (r - startRow) * this._tileHeight + offsetY;

                        displayList[`${c}-${r}`] = {
                            data: `${zoom}/${tile}`,
                            rect: {x, y, width: this._tileWidth, height: this._tileHeight}
                        };
                    }
                }
            }
        }
        return displayList;
    }

    resize(): void {

    }

    destroy(): void {
        super.destroy();

        this._data = null;

    }
}