import {Outlet} from "react-router-dom";
import React, {} from 'react';

const BaseLayout = () => {
    return (
        <div className={'flex-grow flex w-full h-full'}>
            <Outlet />
        </div>
    );
}

export default BaseLayout;