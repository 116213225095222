import Uppy, {UppyFile} from "@uppy/core";
import {FormEvent, forwardRef, ReactElement, useEffect, useRef, useState} from "react";
import DropTarget from "@uppy/drop-target";
import {AnimatePresence} from "framer-motion";
import {useTranslation} from "react-i18next";
import FileThumbnail from "./FileThumbnail";
import FileError from "./FileError";

interface IFileAreaProps {
  onChange: Function,
  name: string,
  uppy: Uppy,
  value : string
  children?: ReactElement | ReactElement[]
}

const FileArea = forwardRef<HTMLDivElement, IFileAreaProps>((props, ref) => {
  
  const {name, uppy, value} = props;


  const dropAreaRef = useRef(null);
  const [dragHover, setDragHover] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const input = useRef<HTMLInputElement | null>(null);
  const [status, setStatus] = useState('');
  
  const {t, i18n} = useTranslation();
  
  const retry = () => {
    setStatus('');
  }

  useEffect(() => {
    if(value && !preview) {
      setPreview(value);
    }
  }, [value,preview]);

  const remove = () => {

    uppy.getFiles().forEach(file => {
      if (file.source === name) {
        uppy.removeFile(file.id, "removed-by-user");
      }
    });

    setPreview(null);

    if (input.current) {
      props.onChange(null);
      input.current.value = '';
    }
  }

  useEffect(() => {

    props.onChange(preview);
  }, [preview])

  useEffect(() => {
    const onThumbnail = (file: UppyFile, preview: string) => {
      console.log(' preview',preview)
      //setPreview(preview);
      if (file.source === name) {
        const img = new Image();
        img.onload = () => {
          //setPreview(preview);
        }
        img.src = preview;
      }
    }
    
    const onFileError = (file: UppyFile | undefined, error: Error) => {
      
      if (file && file.source === name && error && error.message) {
        let text: string
        switch (true) {
          case error.message.indexOf('exceeds') > -1 :
            text = t('file.size')
            setStatus(text);
            break;
          case error.message.indexOf('only upload:') > -1 :
            text = t('simulation.step2.error')
            setStatus(text);
            break;
          default :
            text = t('file.default')
        }
        setStatus(text);
      }
    }
    
    const onFileAdded = (file: UppyFile) => {
      if (file.source === name) {
        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            if (ctx) {
              const scale = Math.max(canvas.width / img.width, canvas.height / img.height)/2;

              //console.log('canvas.width ',canvas.width ,canvas.height,scale)

              canvas.width = img.width * scale;
              canvas.height = img.height * scale;



              const x = (canvas.width / 2) - (img.width / 2) * scale;
              const y = (canvas.height / 2) - (img.height / 2) * scale;

              ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
              file.preview = canvas.toDataURL('image/png', 1);

              console.log('canvas',canvas)
              document.body.appendChild(canvas)

              setPreview(file.preview);
            }
          }
          img.src = reader.result as string;
          console.log('reader.result',reader.result)
        };
        reader.readAsDataURL(file.data);
      }
    }
    
    
    uppy.on('thumbnail:generated', onThumbnail);
    uppy.on('file-added', onFileAdded);
    uppy.on('restriction-failed', onFileError);
    
    return () => {
      uppy.off('thumbnail:generated', onThumbnail);
      uppy.off('file-added', onFileAdded);
      uppy.off('restriction-failed', onFileError);
      
    }
    
  }, [uppy, name]);
  
  const onChange = (e: FormEvent<HTMLInputElement>) => {
    
    //props.onChange(e);
    
    const files = Array.from(e.currentTarget.files || []);
    files.forEach((file) => {
      try {
        uppy.addFile({
          source: name,
          name: file.name,
          type: file.type,
          data: file,
          meta: {
            ext: file.name.split('.').pop()
          }
        })
      } catch (err: any) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
          
        } else {
          // handle other errors
          console.log(err)
        }
      }
    })
  }
  
  useEffect(() => {
    if (dropAreaRef.current) {
      
      uppy.use(DropTarget, {
        id: name,
        target: dropAreaRef.current,
        onDragOver: (event) => {
          setDragHover(true);
        },
        onDrop: (event: MouseEvent) => {
          setDragHover(false);
        },
        onDragLeave: (event) => {
          setDragHover(false);
        },
      })
    }
    
    return () => {
      const plugin = uppy.getPlugin(name);
      if (plugin) {
        uppy.removePlugin(plugin);
      }
    }
    
  }, [name, uppy]);


  return (<div ref={ref} className={`w-full block overflow-hidden `}>
    <div ref={dropAreaRef} className={`relative ${dragHover ? 'bg-lime/30' : ''}`}>
      <input ref={input} accept={'image/png, image/svg+xml'} type={'file'}
             className={`absolute inset-0 opacity-0 cursor-pointer w-full`}
             onChange={(e) => onChange(e)}/>
      {props.children}
      <AnimatePresence>
        {preview && <FileThumbnail remove={remove} url={preview}/>}
        {status !== '' && <FileError retry={retry} message={status}/>}
      </AnimatePresence>
    </div>
  </div>)
  
})
FileArea.displayName = 'FileArea';

export default FileArea