import React, {useEffect} from 'react';
import {NavLink, useParams, useRouteLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PanelContent from "./PanelContent";

const Logos = () => {

    const {panorama, spotID} = useParams();
    const {t, i18n} = useTranslation();
    const data : any = useRouteLoaderData("panel");

    return (
        <PanelContent>
            <h1 className={'text-primary font-extrabold font-sans line'}>
                {t('logo.title')}
            </h1>
            <h3 className={'text-primary'}>{t('logo.choose')}</h3>
            <p className={'mb-6'}>{t('logo.description')}</p>
            <div className={'grid grid-cols-2 gap-4'}>
                {data && data.map((logo : any) => {
                    return (
                        <NavLink
                            to={`/${panorama}/logos/${spotID}/${logo}`}
                            key={logo}
                            className={'cursor-pointer LogoBtn bg-gray-light p-2 overflow-hidden rounded-lg'}>
                            <img src={`/logos/${logo}_ui.png`} />
                        </NavLink>
                    )
                })}
            </div>
        </PanelContent>
    );
}

/*
 /*LOGOS
            <NavLink className={'button button--white'}
                     to={`/${panorama}/logos/${spotID}/1`}
            >
                Dior
            </NavLink>
 */

export default Logos;