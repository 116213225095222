import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

interface IFileThumbnailProps {
    url: string,
    remove: Function
}

const FileThumbnail = ({url, remove}: IFileThumbnailProps) => {

    const {t, i18n} = useTranslation();

    const spring = {
        type: 'tween',
        ease : 'circInOut'
    }

    return (
        <div
            className={`absolute inset-0 bg-gray-light rounded-lg p-4`}>
            <button type={'button'} className={'absolute right-4 top-2 text-primary font-bold z-20'} onClick={() => remove()}>{t('simulation.step2.delete')}</button>
            <motion.img
                transition={{...spring}}
                src={url}
                initial={{opacity : 0}}
                animate={{opacity: 1, transition : {delay : .3,duration: .3}}}
                exit={{opacity : 0,transition : {delay : .2,duration: 0}}}
                className={'w-full h-full object-contain object-center z-0'}
            />
            <motion.span
                transition={{...spring, duration: .5}}
                className={'absolute inset-0 bg-lime z-10'}
                initial={{x: '-100%'}}
                animate={{x: '100%'}}
                exit={{x: '-100%'}}
            />
        </div>
    )
}

export default FileThumbnail