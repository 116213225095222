import React, {useEffect, useState} from 'react';
import {useParams, useRouteLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as Place} from "../assets/svg/icon-place-holder.svg";

export interface InfosProps {
    next : Function
}

const Spot = ({next}: InfosProps) => {

    const {panorama, spotID, logoID} = useParams();
    const {t, i18n} = useTranslation();
    const data: any = useRouteLoaderData("root");
    const [label, setLabel] = useState(null);

    useEffect(() => {

        if(data) {
            const found = data.buildings.find(( building: any) => building.id === spotID);
            if(found) {
                setLabel(found.label);
            }else {
                setLabel(null);
            }
        }

    }, [spotID,data]);

    return (
        <div>
            <h2 className={'text-primary'}>{t('simulation.step1.title')}</h2>
            <p>{t('simulation.step1.description')}</p>
            {label && <div className={'flex flex-col'}>
                <span className={'py-4 text-14 flex items-center gap-2'}><Place className={'text-primary w-6 h-6'} />{label}</span>
                <button onClick={() => next()} type={'button'} className={'w-full flex text-12 bg-primary text-white p-3 items-center justify-center rounded-lg '}>
                    <span>{t('simulation.step1.validate')}</span>
                </button>
            </div>}
        </div>
    );
}

export default Spot;