import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import FileArea from "../nother/components/FileArea";
import Uppy from "@uppy/core";
import {ConnectForm, SimulationFormData} from "./Simulation";
import {useTranslation} from "react-i18next";
import {ReactComponent as Arrow} from '../assets/svg/icon-fleche.svg';
import ImgWrong from '../assets/svg/img-logo-invalide.png'
import ImgGood1 from '../assets/svg/img-logo-valide-1.png'
import ImgGood2 from '../assets/svg/img-logo-valide-2.png'

import {ReactComponent as Doc} from "../assets/svg/icon-doc.svg";
import {ReactComponent as Import} from "../assets/svg/icon-import.svg";

export interface LogoProps {
    back: Function,
    next: Function,
    uppy: Uppy,
    watch: Function
}

const Logo = ({uppy, back, watch, next}: LogoProps) => {

    const {t, i18n} = useTranslation();
    const watchLogo = watch("logo", null);
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {

        if (typeof watchLogo === 'string' && watchLogo.length) {
            setShowNext(true)
        } else {
            setShowNext(false)
        }

    }, [watchLogo])


    return (
        <ConnectForm<SimulationFormData> >

            {({register, control, formState: {dirtyFields, isDirty, errors}}) => (
                <div>
                    <h2 className={'text-primary mb-4'}>{t('simulation.step2.title')}</h2>
                    <Controller
                        name={'logo'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) => <FileArea {...field} uppy={uppy}>
                            <div
                                className={`flex flex-col mb-4`}>
                                <div
                                    className={'flex items-center justify-center bg-gray-light p-3 overflow-hidden rounded-lg mb-4'}>
                                    <Doc className={'block w-10 h-10 flex-shrink-0 mr-4'}/>
                                    <span className={'text-11 font-serif text-gray-dark w-1/2 leading-none '}>
                                      {t('simulation.step2.drop')}
                                  </span>
                                </div>
                                <div
                                    className={'flex bg-primary text-white p-3 items-center justify-center rounded-lg'}>
                                    <Import className={'block w-4 h-4 flex-shrink-0 mr-4'}/>
                                    <span>{t('simulation.step2.cta')}</span>
                                </div>
                            </div>
                        </FileArea>}
                    />

                    <p className={'mb-4'}>
                        {t('simulation.step2.description')}
                    </p>

                    {(t('simulation.step2.rules', {returnObjects: true}) as []).map((item: any, index: number) => {
                        return <span className={'block text-12 text-primary font-serif'} key={item}>- {item}</span>
                    })}
                    <span className={'font-serif text-gray-dark py-4 block text-12'}>{t('simulation.step2.example')}</span>
                    <div className={'flex border-red-500 items-center gap-2'}>
                        <img src={ImgWrong} className={'max-w-full'} />
                        <Arrow />
                        <img src={ImgGood1} className={'max-w-full'} />
                        <Arrow />
                        <img src={ImgGood2} className={'max-w-full'}/>
                    </div>

                    {showNext && <button onClick={() => next()} type={'button'} className={'w-full flex text-12 bg-primary text-white p-3 items-center justify-center rounded-lg mt-4'}>
                        <span>{t('simulation.step2.validate')}</span>
                    </button>}
                </div>
            )}
        </ConnectForm>

    );
}

export default Logo;